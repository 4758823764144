import { HandlerService } from './../handler.service';
import { CookieService } from 'ngx-cookie-service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  credentials:any={
    user:'',
    password:''
  };
  passShow:boolean=false;
  waiting:boolean=false;
  constructor(private service:HandlerService,private router:Router,private cookie:CookieService) { }

  ngOnInit() {
    if(this.service.getCookie("user")){
      let u = JSON.parse(this.service.getCookie("user"))
      if(u.token==btoa(u.user+"Y29iYWx0ZmF")){
        this.router.navigate(['/home'])
      }
    }
    console.log(this.service.getCookie("user"));
  }

  login(){
    this.waiting=true;
    setTimeout(()=>{
      this.service.login(this.credentials).subscribe(resp=>{
        this.waiting=false;
        if(resp.code){
          switch(resp.code){
            case 200: 
              this.service.setUser({user:this.credentials.user})
              this.service.setCookie('user',JSON.stringify({user:this.credentials.user,token:btoa(this.credentials.user+"Y29iYWx0ZmF")}),2)
              this.router.navigate(['/'])
            break;
            default:
              Swal.fire({icon:'error',text:"Login failed: "+resp.message})
          }
        }else{
          Swal.fire({icon:'error',text:"Login request failed."})
        }
      })
    },1500);
  }

}
